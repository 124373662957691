.Page--Quiz {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2em;
  box-sizing: border-box;
  @media (orientation: portrait){
    padding-top: .25em;
    align-items: flex-start;
  }
}


.Page--Quiz > .Page__content {
  display: grid;
  align-items: unset;
  grid-template-areas:
            "a b e"
            "c b .";
  grid-template-columns:  repeat(1, minmax(0, 1fr));
  grid-template-rows:  repeat(1, minmax(0, 1fr));
  width: min(60em, 90vw);
  @media(orientation: landscape) {
    height: 85vh;
  }
  gap: 3em;
  @media (orientation: portrait) {
    //grid-template-areas:
    //        "d"
    //        "a"
    //        "b"
    //        "c";
    margin: 0;
    //gap: 0.25em;
    grid-gap: 0;
    gap: 0;
    max-width: 90vw;
    width: 90vw;
    display: flex;
    flex-direction: column;
  }
  @media (orientation: landscape) {
    margin-bottom: 0;
    margin-top: 0;
    min-height: unset;
  }
}

.Transition {
  position: absolute;
  //overflow: hidden;
  transform: translate(-150em, 0);
  opacity: 1;
  top: 0;
  width: 95vw;
  max-width: 95vw;
  height: 95vh;
  z-index: 555;
  & > img {
    max-height: 95vh;
  }

  @media (orientation: portrait) {
    height: 100vh;
    & > img {
      max-height: 100vh;
    }

  }
}

.Quiz--fadeout > .Page__content > .Transition {
  animation: transition-mob 4s linear forwards .5s;
  @media (orientation: landscape) {
    animation: transition 2s linear forwards .5s;
  }

  & > img {
    animation: transition-opacity 2s linear forwards;
    @media (orientation: landscape){
      animation: transition-opacity 2.8s linear forwards;
    }
  }
}

@keyframes transition-opacity {
  0% {
    opacity: 1;
  }
  79% {
    opacity: 1;
  }
  85% {
    opacity: .85;
  }
  100% {
    opacity: 0;
  }
}

@keyframes transition-mob {
  0% {
    transform: translate(-120em, 0);
  }
  25% {
    transform: translate(-45em, 0);
  }
  40% {
    transform: translate(-10em, 0);
  }
  50% {
    transform: translate(-5em, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes transition {
  0% {
    transform: translate(-120em, 0);
  }
  100% {
    transform: translate(0em, 0);
  }
}

.Quiz__icons {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
}

.Page--Quiz > .Page__content > .Page__samokat {
  @media (orientation: landscape) {
    display: none;
  }
  grid-area: d;
  height: 0.75em;
  position: unset;
  margin: 1em auto;
}

.Page--Quiz > .Page__content > .Page__samokat--circle {
  @media (orientation: portrait) {
    display: none;
  }
  grid-area: e;
  height: 2.5em;
}

.Page__quizCard {
  grid-area: a;
  @media (orientation: landscape) {
    width: 25em;
    max-width: 100%;
    //max-width: 60vh;
    justify-self: right;
  }
}

.Page--Quiz {
  transition: 1s opacity;
}

.Quiz--fadein {
  //opacity: 1;
}

.Quiz--fadeout {
  //transition-delay: 2s;
  //opacity: 0;
}


.Page--Quiz > .Page__content > .Page__logo {
  height: 100%;
  grid-area: b;
  @media (orientation: portrait) {
    display: flex;
    justify-content: center;
    //min-height: 35vh;
    //max-height: 35vh;
    //margin-bottom: -.5vh;
  }
  @media (orientation: landscape) {
    //height: 28em;
    justify-self: left;
    //max-width: 28vw;
    min-width: 25em;
    max-width: 25em;
  }

  & > img {
    max-width: 100%;
    max-height: 100%;
    @media (orientation: landscape) {
      width: auto;
      height: 100%;
    }
    @media (orientation: portrait) {
      //width: calc(2em + 35vh);
      //max-height: 30vh;
    }
  }
}

.Page__answers {
  grid-area: c;
  display: grid;
  grid-template-areas:
            "a b"
            "c d";
  grid-gap: 1em 1.75em;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  @media (orientation: portrait) {
    grid-gap: 0.5em 0.55em;
    position: absolute;
    bottom: 2em;
    margin: auto;
    max-width: 90vw;
  }
  @media (orientation: landscape) {
    width: 25em;
    max-width: 100%;
    //max-width: 60vh;
    justify-self: right;
  }
}

.Page__answers > .AnswerCard:first-child {
  grid-area: a;
}

.Page__answers > .AnswerCard:nth-child(2) {
  grid-area: b;
}

.Page__answers > .AnswerCard:nth-child(3) {
  grid-area: c;
}

.Page__answers > .AnswerCard:nth-child(4) {
  grid-area: d;
}
