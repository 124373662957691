@font-face {
  font-family: Euclid Circular A;
  src: local("Euclid Circular A"),
  url("../fonts/Euclid Circular A Regular.ttf") format("truetype");
  font-weight: normal;
}

$default-font: Euclid Circular A;

@font-face {
  font-family: Euclid Circular A Bold;
  src: local("Euclid Circular A Bold"),
  url("../fonts/Euclid Circular A Bold.ttf") format("truetype");
  font-weight: bold;
}

$bold-font: Euclid Circular A Bold;
