@import "../../styles/constants";

.Card {
  background-color: white;
  border-radius: $border;
  @media (orientation: portrait){
    border-radius: $border-mobile;
  }
  height: max-content;
  @media (orientation: portrait) {
    width: 90vw;
  }
}

.Card__content {
  //min-height: 7.8em;
  max-width: 20em;
  padding: 1.5em 3em;
  text-align: left;
  font-size: .95em;
  @media(orientation: portrait){
    font-size: .75em;
  }
}

.Card__corner {
  transition: all .5s;
  transform: translate(7em, 0px);
  max-width: 8px;
  height: 1px;

  &:before {
    opacity: 1;
    transition: opacity .1s;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIyMHB4IiBoZWlnaHQ9IjhweCIgdmlld0JveD0iMCAwIDIwIDgiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+ICAgICAgICA8dGl0bGU+dG9vbHRpcF90aXA8L3RpdGxlPiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4gICAgPGRlZnM+PC9kZWZzPiAgICA8ZyBpZD0iTWFzdGVyLUtpdCIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+ICAgICAgICA8cGF0aCBkPSJNMTAsMCBDMTMuNSwwIDE1LjUsOCAyMCw4IEwwLDggQzQuNSw4IDYuNSwwIDEwLDAgWiIgaWQ9InRvb2x0aXBfdGlwIiBmaWxsPSIjZmZmIiBmaWxsLXJ1bGU9Im5vbnplcm8iPjwvcGF0aD4gICAgPC9nPjwvc3ZnPg==);
    content: "";
    height: 8px;
    width: 20px;
    position: absolute;
    transform: rotate(180deg);
  }

  @media screen and (max-width: 1000px) {
    transform: translate(2em, 0px);
  }
}

.Card__corner--left {
  transform: translate(5.5em, 0px);
  @media screen and (max-width: 1000px) {
    transform: translate(3em, 0px);
  }
}

.Card__corner--right {
  transform: translate(14.5em, 0px);
  @media screen and (max-width: 1000px) {
    transform: translate(12em, 0px);
  }
}

.Card__corner--none {
  &:before {
    opacity: 0;
  }
}
