$bg: #ff335f;
$second: #C1C1C1;
$bg-button: #000000;
$bg-button-disabled: #949492;
$bg-hover: #000000;
$bg-link: #F2EBEB;
$link: #ff335f;
$active-stage: rgba(254, 2, 91, 0.6);
$finished-stage: #F2EBEB;
$next-stage: transparent;
