@import "../../styles/constants";

.AnswerCard {
  min-height: 5.5em;
  border: 0.09em solid #FFFFFF;
  background: transparent;
  color: white;
  border-radius: $border;
  @media (orientation: portrait){
    border-radius: $border-mobile;
    min-height: 5.25em;
    height: 5.25em;
    max-height: 5.25em;
  }
  transition: background .5s;
  position: sticky;
  &:not(.AnswerCard--disabled, .AnswerCard--correct, .AnswerCard--not-correct){
    cursor: pointer;
  }
}

@media (hover: hover) {
  .AnswerCard:hover:not(.AnswerCard--disabled, .AnswerCard--correct, .AnswerCard--not-correct) {
    background: black;
    border-color: transparent;
  }
}

.AnswerCard--correct {
  background: #00EA85;
  color: black;
  border-color: transparent;
}

.AnswerCard--not-correct {
  background: #720F37;
  border-color: transparent;
}

.AnswerCard__content {
  padding: .5em;
  font-weight: 400;
  text-align: left;
  @media (orientation: portrait){
    padding: 1em 1em 2em 1em;
    font-size: .6em;
    max-width: 100%;
    min-width: 30vw;
  }
  & p {
    margin: 0;
    font-size: 1.15em;
    @media (orientation: landscape){
      font-size: .75em;
    }
  }
}

.AnswerCard__icon {
  position: absolute;
  right: .5em;
  top: .5em;
  & > img {
    height: 1em;
  }
}
