@import "../../styles/colors";

.Page {
  background-color: $bg;
  min-height: 100vh;
  min-width: 100%;
  @media (orientation: landscape){
    width: max-content;
    height: max-content;
    box-sizing: border-box;
    padding: 2em;
  }
  display: flex;
  justify-content: center;
}

.Page__content {
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 100%;
  justify-content: center;
  @media (orientation: landscape) {
    margin: 2em 0;
    font-size: 2.7em;
  }
  @media (orientation: portrait){
    //min-height: 100%;
  }
}
