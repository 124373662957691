@import '../../styles/colors';

.Button {
  background-color: #000000;
  color: white;
  border-radius: 5rem;
  padding: 0.85em;
  opacity: 1;
  transition: opacity 0.5s;
  border: none !important;
  width: 10em;
  font-size: 1.25em;
  font-weight: 400;
  @media (orientation: portrait){
    width: 75vw;
    padding: 1em 0.85em;
  }
  &:hover {
    opacity: .8;
    cursor: pointer;
  }

  &[disabled] {
    opacity: .25;
    cursor: not-allowed;
  }
}

button:focus {
  outline: 0 !important;
}
