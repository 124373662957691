@import "../../styles/constants";

.Page__choice {
  margin: 1.25em 0;
  z-index: 2;
  display: flex;
  min-height: 14em;
  @media (orientation: portrait){
    min-height: 35vh;
    margin: 0 0 1em;
  }
}

.Page__choice__block {
  margin: .5em;
}

.Page--Pick > .Page__content {
  @media (orientation: landscape){
    justify-content: unset;
  }
  @media (orientation: portrait){
    & > .Page__button > .Button {
      width: 8.5em;
      padding: 1em 0.85em;
    }
  }
}
//.Page--Pick > .Page__content > .Card {
//  @media (orientation: portrait) {
//    margin-top: calc(.6em + 1.25vh);
//  }
//}

.Page--Pick > .Page__content > .Card {
  @media (orientation: portrait){
    margin-top: -3.5em;
    margin-bottom: 3.5em;
  }
}

.Page--Pick > .Page__content > .Card > .Card__content {
  padding: 1em;
  min-height: 8em;
  max-height: 8em;
  @media (orientation: landscape){
    line-height: 1.5em;
  }
  @media (orientation: portrait){
    font-size: 1em;
    line-height: 1.25em;
    min-height: 8.5em;
    max-height: 8.5em;
    @media (max-height: 900px){
      font-size: 1em;
      min-height: 6.5em;
      max-height: 8.5em;
    }
  }
}

.Pick__text {
  margin: 0;
}

.Pick__promo {
  display: none;
  background: black;
  border-radius: $border;
  @media (orientation: portrait){
    border-radius: $border-mobile;
  }
  color: white;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 0.25em;
  box-sizing: border-box;
  width: 90vw;
  margin-top: 1rem;
  @media (orientation: landscape){
    border-radius: 50%;
    margin-top: -2.5em;
    margin-left: 20em;
    height: 9.7em;
    width: 9.7em;
  }
  & p {
    font-size: 1em;
    @media (orientation: portrait){
      font-size: .85em;
    }
  }
}
