@import "../../styles/colors";
@import "../../styles/constants";
@import "../../styles/fonts";

.QuizCard {
  background-color: white;
  border-radius: $border;
  height: 100%;
  @media (orientation: portrait){
    border-radius: $border-mobile;
    height: calc(3em + 3.75em + 1em);
  }

}

.QuizCard__content {
  gap: .5em;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  padding: 1em;
  text-align: left;
  @media (orientation: portrait) {
    max-width: unset;
    //min-height: 7.35em;
    padding-left: 0.75em;
    padding-right: 0.69em;
  }
}

.QuizCard__header {
  font-family: $bold-font;
  font-weight: 700;
  font-size: 1em;
  @media(orientation: portrait){
    padding-bottom: .25em;
    font-size: 1em;
    width: 103%;
  }
  @media (orientation: landscape){
    line-height: 1.5em;
  }
  & p {
    margin: 0;
    display: contents;
    font-weight: 200;
    font-family: $default-font;
  }
}

.QuizCard__stage {
  color: $second;
  font-size: .75em;
  @media (orientation: portrait){
    display: none;
  }
}
