@import "../../styles/colors";
@import "../../styles/constants";
@import "../../styles/fonts";

.Page--Finish {
  padding: 0 !important;

  & strong {
    font-family: $bold-font;
    font-weight: 900;
  }
}

.Page--Finish > .Page__content {
  margin-bottom: 0;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  @media (orientation: landscape) {
    margin-top: 2em;
  }
}

.Page--Finish .Card {
  border-radius: 1em;
  max-width: 25em;
}

.Card__social__image {
  & img {
    @media(orientation: landscape) {
      height: 6em;
    }
  }
}

.Page--Finish > .Page__content > .Page__block {
  margin: auto;
  display: grid;
  align-items: unset;

  grid-template-areas:
  ". f"
            "a e"
            "b e"
            "c e"
            "d d";
  grid-template-columns: repeat(2, minmax(0, 1fr));
  width: min(60em, 90vw);
  //max-height: 40em;
  gap: 1.25em;
  min-height: unset;
  @media (orientation: portrait) {
    max-height: unset;
    display: flex;
    justify-content: center;
    //align-items: center;
    max-width: 90vw;
    gap: 1em;
    margin-bottom: 0;
    flex-direction: column;
  }
}


.Quiz__icons > img {
  height: 1.25em;
}

.Page--Finish > .Page__content > .Page__block > .Page__header {
  font-family: $bold-font;
  grid-area: a;
  font-size: 2.25em;
  color: white;
  font-weight: 700;
  @media (orientation: portrait) {
    font-size: 1.5em;
    align-self: flex-start;
    text-align: left;
  }
}

.Page--Finish > .Page__content > .Page__block > .Page__logo {
  grid-area: e;
  height: min(32em, 50vw);
  max-height: min(32em, 50vw);

  & > img {
    height: 100%;
  }

  display: flex;
  //justify-content: center;
  @media (orientation: portrait) {
    //max-height: 17em;
    margin-top: 1.5em;
    display: flex;
    justify-content: center;
    margin-bottom: -1.25em;
    max-height: unset;
    height: 100%;
  }
}

.Page__promo {
  background: $bg-link;
  font-family: $default-font;
  font-weight: normal;
  color: $link;
  border-radius: $border;
  @media(orientation: portrait) {
    border-radius: $border-mobile;
  }
  cursor: pointer;
  padding: 0.05em 0.9em;
  font-size: 0.94em;
  width: min-content;
  display: inline;
  margin: 0;
  @media(orientation: portrait) {
    line-height: 2em;
  }
}

.Page__header--desc {
  @media(orientation: portrait) {
    display: none;
  }
  justify-self: right;
  grid-area: f;
  height: 1.35em;
}

.Page--Finish > .Page__content > .Page__block > .Card:not(.Share) {
  grid-area: b;

  & > .Card__content {
    max-width: 90vw;
    line-height: 1.35em;
    font-size: 1em;
    @media(orientation: landscape) {
      max-height: 15em;
      min-height: 15em;
      padding: 1.5em 1.56em;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      & p {
        margin: 0;
      }
    }
    @media (orientation: portrait) {
      font-size: 1em;
      padding: .95em;
    }

    & > p {
      display: inline;
    }

    & > .Card__social {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      @media(orientation: portrait) {
        flex-direction: column;
        align-items: center;
        gap: .75em;
        margin-top: 1.5em;
      }

      & > .Card__social__button {
        width: 100%;

        & > .Button {
          width: 100%;
          font-size: 1em;
          display: block;
          padding: 1em 0.4em;
          margin-bottom: 1em;
        }
      }

      & > .Card__social__text {
        @media (orientation: landscape) {
          font-size: .9em;
          max-width: 65%;
        }
        @media(orientation: portrait) {
          max-width: 100%;
          font-size: .9em;
        }
      }
    }
  }
}

.Page--Finish .Card {
  @media(orientation: portrait) {
    border-radius: $border-mobile;
  }
}

.Share {
  grid-area: c;
  background: black;
  color: white;
  font-size: .95em;
  @media (orientation: landscape) {
    //height: 100%;
    justify-content: center;
    display: flex;
  }

  & > .Card__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    max-width: unset;
    @media (orientation: portrait) {
      display: flex;
      flex-direction: column;
      justify-content: left;
      gap: 1.25em;
      font-size: 1em;
      text-align: left;
      align-items: flex-start;
      padding: .95em;
    }
    @media (orientation: landscape) {
      font-size: 0.75em;
    }
  }
}

.Share__text {
  @media(orientation: portrait) {
    font-size: .9em;
  }
}

.Share__buttons {
  gap: 0.85em;
  display: flex;
  justify-content: space-between;
  width: 40%;
  @media (orientation: portrait) {
    width: 100%;
    justify-content: flex-start;
  }
}

.Share__button {
  background: white;
  border-radius: 50%;
  color: black;
  padding: .95em;
  cursor: pointer;
  transition: transform .5s;
  height: 1em;
  width: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  & svg {
    //display: none;
    opacity: 0;
    transition: all 1s;
    position: absolute;
    color: white;
    height: 2em;
    width: 2em;
  }
  @media(orientation: landscape) {
    & p {
      transition: all 1s;
    }
    &:hover {
      & p {
        position: absolute;
        color: white;
      }

      & svg {
        top: auto;
        bottom: auto;
        left: auto;
        right: auto;
        color: black;
        height: 2em;
        width: 2em;
        //display: block;
        opacity: 1;
      }
    }
  }

  @media (orientation: portrait) {
    &:hover {
      transform: none;
    }
    &:active {
      transform: translate(-.25em, -.25em);
    }

    height: 1em;
    width: 1em;
    font-size: 1em;
  }
}

.__react_component_tooltip.show {
  border-radius: $border !important;
  opacity: 1 !important;
  padding: 0.5em 1em !important;
  z-index: 5555 !important;
  @media (orientation: portrait) {
    border-radius: $border-mobile !important;
  }
}

.Button__pulse {
  position: relative;
  box-shadow: 0 0 0 0 rgba(black, .5);
  animation: 2s pulse infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(black, 0);
  }
  50% {
    box-shadow: 0 0 .15em .45em rgba(black, .25);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(black, 0);
  }
}

//
//.Footer__text--white {
//  color: white;
//}
//
//.Page__footer {
//  color: #797979;
//  width: 100vw;
//  text-align: center;
//  background: black;
//  padding: .85em;
//  box-sizing: border-box;
//
//  @media (orientation: portrait) {
//    margin-top: 1.5em;
//  }
//
//  & > .Footer__content {
//    display: flex;
//    flex-direction: column;
//    align-items: flex-start;
//    justify-content: space-evenly;
//    gap: 1.5em;
//    @media (orientation: landscape){
//      flex-direction: row;
//      align-items: center;
//    }
//  }
//
//  & > .Footer__content > .Footer__button > .Button {
//    border: 1px solid #797979 !important;
//    color: #797979;
//    @media (orientation: landscape) {
//      padding: 0.75em;
//    }
//    @media(orientation: portrait) {
//      font-size: .85em;
//      width: 100%;
//    }
//  }
//
//  & a:not(.a_mail) {
//    text-decoration: underline;
//    color: white;
//    cursor: pointer;
//  }
//}
//
//.Footer__mail {
//  font-size: .55em;
//  @media(orientation: landscape){
//    font-size: 1.05em;
//  }
//}
//
//.Footer__text--not-correct {
//  display: none;
//}
//
//.Footer__text__last {
//  display: flex;
//  gap: 1.25em;
//  @media (orientation: landscape){
//    width: 20%;
//  }
//}
//
//.Footer__subtitle, .Footer__org {
//  color: #797979;
//  max-width: 80%;
//  line-height: 1.45em;
//  font-size: 0.45em;
//}
//
//.Footer__text--correct > .Footer__subtitle {
//  @media (orientation: landscape){
//    width: unset;
//  }
//}
//
//.Footer__subtitle--not-correct {
//  display: none;
//}
//
//.Footer__subtitle--correct {
//  width: 100%;
//  max-width: unset;
//}
//
//.Footer__org {
//  width: 100%;
//  max-width: unset;
//}

.a_mail {
  color: #ff335f;
}

.Logo__samokat {
  height: 1em;
  @media(orientation: landscape) {
    display: none;
  }
}

//.Footer__button {
//  @media(orientation: portrait) {
//    width: 80%;
//  }
//}
//
//.Footer__text {
//  text-align: left;
//}

//.Page__link--not-correct {
//  display: none;
//}

.Footer {
  box-sizing: border-box;
  background: black;
  width: calc(100vw - var(--scrollbar));
  display: flex;
  justify-content: center;
  padding: 1.5em 0;
  @media(orientation: portrait) {
    padding: 2em 1.25em;
    margin-top: 1.5em;
    width: 100vw;
  }
}

.Footer__content {
  display: grid;
  grid-template-areas: 'a c';
  align-items: center;
  width: min(60em, 90vw);
  @media (orientation: portrait) {
    grid-template-areas: 'd'
            'a'
      'b'
      'c';
    gap: 2em;
  }
}

.Footer__button {
  grid-area: a;
}

.Footer__button > .Button {
  border-radius: unset !important;
  border: none !important;
  text-decoration: underline;
  color: #797979;
  font-size: .8em;
  width: max-content;
  @media (orientation: landscape) {
    text-align: left;
  }
  padding: 0;
}

.Footer__button--croissant {
  @media (orientation: portrait) {
    margin-top: -1.5em;
  }
}

.Logo__samokat {
  grid-area: b;
  margin-bottom: -1em;
  @media (orientation: landscape) {
    display: none;
  }
}

.Footer__subtitle--mobile {
  grid-area: d;
  width: 100%;
  display: grid;
  color: #797979;
  font-size: .35em;
  @media(orientation: portrait) {
    font-size: .5em;
  }
}

.Footer__subtitle {
  grid-area: c;
  color: #797979;
  max-width: 55em;
  font-size: .45em;
  justify-self: flex-end;
  gap: 8em;
  align-items: center;
  display: flex;
  justify-content: space-evenly;

  @media (orientation: portrait) {
    font-size: .5em;
    max-width: unset;
    gap: 1.5em;
  }
}

.Footer__subtitle--soap {
  max-width: 100%;
}

.Footer__subtitle--soap {
  & > .Footer__advertisement {
    max-width: 20%;
  }

  & div {
    max-width: 50%;
  }
}

.Footer__text {
  font-size: 1em;
  max-width: 22%;
}

.Footer__mail {
  color: white;
  font-size: 1em;

  & a {
    color: #ff335f;
    text-decoration: unset;
    color: inherit;
  }
}

.Footer__subtitle a {
  color: inherit;
}

.Page__header--mobile {
  background: black;
  width: 100vw;
  height: 2.25em;
  padding: 0.65em;
  display: flex;
  justify-content: left;
  box-sizing: border-box;
  //margin-bottom: -2.25em;

  & img {
    height: .65em;
  }

  @media (orientation: landscape) {
    display: none;
  }
}
