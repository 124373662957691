.Picked {
  margin: .5em;
  @media (orientation: portrait) {
    margin: .25em;
  }
  transition: all .5s;
  display: flex;
  justify-content: center;

  & > img {
    transition: all .5s;
    width: 9em;
    cursor: pointer;
  }
}

.Picked--active {

  z-index: 4;
  @media (orientation: landscape) {
    //margin: -3.5em;
    margin-bottom: -2.5em;
  }
  @media (orientation: portrait) {
    margin-bottom: 0;
  }

  & > img {
    @media(orientation: landscape) {
      width: 12em;
    }
    @media (orientation: portrait) {
      transform: scale(1.35);
    }
  }
}

.Picked--active--soap {
  margin-right: .5em;
  margin-left: -2em;
  @media (orientation: landscape) {
    margin-left: -2.5em;
  }
}

.Picked--active--croissant {
  margin-left: .5em;
  margin-right: -2em;
  @media (orientation: landscape) {
    margin-right: -2.5em;
  }
}

.Picked--inactive {
  opacity: .5;
  @media (orientation: portrait) {
    margin-bottom: 0;
  }

  & > img {
    @media (orientation: landscape) {
      width: 8em;
    }
    @media (orientation: portrait){
      transform: scale(.9);
    }
  }
}
