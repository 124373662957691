@import "../../styles/fonts";

.Page--Main {
  color: white;
  & > .Page__content {
    //gap: .85em;
    //transition: font-size .5s;
    @media (orientation: portrait){
      justify-content: center;
    }
  }
}

.Only--mobile {
  @media (orientation: landscape) {
    display: none;
  }
}

.No--mobile {
  @media (orientation: portrait) {
    display: none;
  }
}

.Page__gallery {
  transition: all .5s;
  position: fixed;
  background: black;
  font-size: 1em;
  min-height: 1.5em;
  display: flex;
  white-space: nowrap;
  align-items: center;
  overflow: hidden;
  @media(orientation: landscape) {
    width: 100vw;
  }
  left: 0;
  bottom: 0;
  &:nth-child(1){
    top: 0;
    bottom: unset;
  }
  & > span {
    display: inline-block;
    animation: gallery 75s linear infinite;
  }
}

@keyframes gallery {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

.Page__samokat {
  height: 1.25em;
  margin-bottom: 2em;
  margin-top: -2em;
  @media (orientation: portrait){
    //position: absolute;
    //top: 10vh;
    position: absolute;
    top: 3.5em;
    margin: 0;
  }
}

.Page--Main > .Page__content > .Page__logo {
  height: 14em;
  @media (orientation: portrait){
    height: 11em;
  }
}

.Page--Main > .Page__content > .Page__subtitle {
  font-size: .925em;
  line-height: 1.35em;
  text-align: center;
  margin-bottom: 1.35em;
  @media (orientation: portrait){
    margin-left: 1.35em;
    margin-right: 1.35em;
    font-size: 0.85em;
  }
}

.Page--Main > .Page__content > .Page__title {
  font-family: $bold-font;
  font-weight: 700;
  font-size: 4em;
  margin: 0.25rem 0 0.75rem 0;
  text-align: center;
  @media (orientation: portrait){
    font-size: 2.65em;
  }
}
